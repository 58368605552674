// extracted by mini-css-extract-plugin
export var botaoloja = "semanadacarnesuina-module--botaoloja--ff085";
export var diaLogo = "semanadacarnesuina-module--diaLogo--e59b5";
export var diaexpress = "semanadacarnesuina-module--diaexpress--4f909";
export var diasOfertas = "semanadacarnesuina-module--diasOfertas--754a7";
export var dropbtn = "semanadacarnesuina-module--dropbtn--e7600";
export var dropdown = "semanadacarnesuina-module--dropdown--b867a";
export var dropdownContent = "semanadacarnesuina-module--dropdownContent--47f8c";
export var fontMobileH2 = "semanadacarnesuina-module--fontMobileH2--09c8a";
export var height1 = "semanadacarnesuina-module--height1--6d35e";
export var lineHeightCustom = "semanadacarnesuina-module--lineHeightCustom--b61c5";
export var logoPig = "semanadacarnesuina-module--logoPig--fedec";
export var mask = "semanadacarnesuina-module--mask--71699";
export var spacedMenu = "semanadacarnesuina-module--spacedMenu--b68b4";
export var spacedMenuIcons = "semanadacarnesuina-module--spacedMenuIcons--ceab2";
export var topo = "semanadacarnesuina-module--topo--6dea2";